import React from 'react'
import { PageTransition } from '../components/transition'

const SpeiseplanPage = () => (
  <PageTransition
    style={{ backgroundColor: 'white', display: 'flex', flex: 1 }}
  />
)

export default SpeiseplanPage
